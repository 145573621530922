var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col relative h-full"},[_vm._m(0),(_vm.threads.length > 0)?_vm._l((_vm.threads),function(thread,idx){return _c('div',{key:idx,staticClass:"flex flex-row py-3 px-3 cursor-pointer",on:{"click":function($event){return _vm.openThread(thread.id)}}},[_c('div',{staticClass:"pointer-events-none"},[_c('svg',{staticClass:"h-12 mb-2 text-blue-600 fill-current",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"}})])]),_c('div',{staticClass:"message pointer-events-none",class:{
                    'text-gray-900 font-semibold': thread.unread > 0,
                    'text-gray-800': thread.unread === 0,
                }},[_c('div',{staticClass:"flex flex-row justify-between items-center text-gray-800"},[_c('span',{staticClass:"text-lg"},[_vm._v(_vm._s(_vm.getSubject(thread.message)))]),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.formatTime(thread.message.createdAt)))])]),_c('div',{staticClass:"py-1 text-sm",class:{
                        'text-gray-800 font-semibold': thread.unread > 0,
                        'text-gray-700': thread.unred === 0,
                    }},[_c('p',{staticClass:"truncate"},[_vm._v(_vm._s(thread.message.message))])])])])}):[_c('div',{staticClass:"flex flex-row justify-center mt-24"},[_c('svg',{staticClass:"h-24 fill-current text-gray-500",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M0 0h24v24H0V0z","fill":"none"}}),_c('path',{attrs:{"d":"M19 3H4.99c-1.11 0-1.98.89-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.11-.9-2-2-2zm0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19v10z"}})])])],_c('button',{staticClass:"absolute w-32 right-3 bottom-6 py-3 rounded-full text-sm text-white shadow-lg bg-orange-500 hover:bg-orange-600",on:{"click":_vm.openStartChat}},[_c('svg',{staticClass:"h-5 pointer-events-none inline mr-1 fill-current",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M0 0h24v24H0z","fill":"none"}}),_c('path',{attrs:{"d":"M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z"}})]),_vm._v(" Start Chat ")])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3"},[_c('h3',{staticClass:"text-lg text-center text-gray-800"},[_vm._v("Messages")])])
}]

export { render, staticRenderFns }