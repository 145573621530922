<template>
    <div class="flex flex-col relative h-full">
        <div class="py-3">
            <h3 class="text-lg text-center text-gray-800">Messages</h3>
        </div>
        <template v-if="threads.length > 0">
            <div
                v-for="(thread, idx) in threads"
                :key="idx"
                class="flex flex-row py-3 px-3 cursor-pointer"
                @click="openThread(thread.id)">
                <div class="pointer-events-none">
                    <svg class="h-12 mb-2 text-blue-600 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/></svg>
                </div>
                <div
                    :class="{
                        'text-gray-900 font-semibold': thread.unread > 0,
                        'text-gray-800': thread.unread === 0,
                    }"
                    class="message pointer-events-none">
                    <div class="flex flex-row justify-between items-center text-gray-800">
                        <span class="text-lg">{{ getSubject(thread.message) }}</span>
                        <span class="text-sm">{{ formatTime(thread.message.createdAt) }}</span>
                    </div>
                    <div
                        :class="{
                            'text-gray-800 font-semibold': thread.unread > 0,
                            'text-gray-700': thread.unred === 0,
                        }"
                        class="py-1 text-sm">
                        <p class="truncate">{{ thread.message.message }}</p>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="flex flex-row justify-center mt-24">
                <svg class="h-24 fill-current text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 3H4.99c-1.11 0-1.98.89-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.11-.9-2-2-2zm0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19v10z"/></svg>
            </div>
        </template>
        <button
            class="absolute w-32 right-3 bottom-6 py-3 rounded-full text-sm text-white shadow-lg bg-orange-500 hover:bg-orange-600"
            @click="openStartChat">
            <svg class="h-5 pointer-events-none inline mr-1 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 9h12v2H6V9zm8 5H6v-2h8v2zm4-6H6V6h12v2z"/></svg>
            Start Chat
        </button>
    </div>
</template>

<script>
import { DateTime } from 'luxon';

export default {
    name: 'MessageThreads',

    props: {
        threads: {
            type: Array,
            default: () => [],
        },

        openStartChat: {
            type: Function,
            default: () => () => {},
        },

        openThread: {
            type: Function,
            default: () => () => {},
        },
    },

    data() {
        return {
            localThreads: [],
        };
    },

    watch: {
        threads(newValue) {
            this.localThreads = newValue;
        },
    },

    methods: {
        getSubject(message) {
            if (message) {
                return message.direction === 'outgoing'
                    ? message.to
                    : message.from;
            }

            return 'Invalid';
        },

        formatTime(date) {
            const startDate = DateTime.fromMillis(date);
            const endDate = DateTime.local();
            const diffInMinutes = endDate.diff(startDate, 'minutes').toObject();

            if (date) {
                if (diffInMinutes.minutes < 60) {
                    const formatted = `${Math.ceil(diffInMinutes.minutes)}m`;
                    return formatted;
                }

                const formatted = startDate.toLocaleString(DateTime.TIME_24_SIMPLE);
                return formatted;
            }

            return 'Invalid';
        },
    },
};
</script>

<style lang="postcss" scoped>
.message {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 4.5rem;
    right: .75rem;
}
</style>
